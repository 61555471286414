

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      snackbar: state => state.savingSnackbar,
      snackbarIcon: state => state.savingSnackbarIcon,
      snackbarIconColor: state => state.savingSnackbarIconColor,
      snackbarText: state => state.savingSnackbarText,
      snackbarTimeout: state => state.savingSnackbarTimeout
    })
  },
  methods: {
    closeSnackbar() {
      this.$store.commit('CLOSE_SAVING_SNACKBAR')
    }
  }
};
